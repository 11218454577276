import {
  blue,
  green,
  orange,
  purple,
  red,
  volcano,
  yellow,
} from "@ant-design/colors";
import {
  BayUtilizationResponse,
  EfficiencyResponse,
  MaintenanceByUnitTypeResponse,
  VehicleType,
} from "../../../api/types";
import {
  MaintenanceByUnitTypeChartItem,
  maintenanceByUnitTypeChartVehicle,
} from "../components/charts/mock/chartsMock";

export const DATE_FORMAT = "DD/MM/YYYY hh:mm A";
export const DATE_FORMAT_WITHOUT_HOURS = "DD/MM/YYYY";

export const vehicleTypesColors = [
  { id: 0, color: blue.primary },
  { id: 1, color: orange.primary },
  { id: 2, color: green.primary },
  { id: 3, color: purple.primary },
  { id: 4, color: yellow.primary },
  { id: 5, color: red.primary },
  { id: 6, color: volcano.primary },
  { id: 7, color: volcano.primary },
];

export const camelize = (str: string) => {
  if (typeof str !== "string") return "";
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const uncamelize = (text: string, separator = " ") => {
  const result = text
    .replace(/[A-Z]/g, (letter) => separator + letter.toUpperCase())
    .replace("/^" + separator + "/", "");
  return result.charAt(0).toUpperCase() + result.substring(1);
};

export const getVehiclesFromMaintenanceByUnitTypeChartData = (
  data: MaintenanceByUnitTypeChartItem[]
) => {
  return data.reduce((acc: any, cur: MaintenanceByUnitTypeChartItem) => {
    const vehicles = cur.vehicles.reduce((acc: any, cur) => {
      const vehicleItem = {
        label: cur.name,
        value: cur.activeHours,
      };
      acc.push(vehicleItem);
      return acc;
    }, []);
    vehicles.forEach((vehicle: any) => {
      if (!acc.find((item: any) => item.label === vehicle.label)) {
        acc.push(vehicle);
      }
    });
    return acc;
  }, []);
};

export const convertDataToMaintenanceByUnitTypeChart = (
  data: MaintenanceByUnitTypeChartItem[]
) => {
  return data
    .filter((item) => item.vehicles.length > 0)
    .reduce((acc: any, cur) => {
      const vehicles = cur.vehicles.reduce(
        (acc: any, cur: maintenanceByUnitTypeChartVehicle) => {
          acc[camelize(cur.name)] = cur.activeHours;
          return acc;
        },
        {}
      );
      const chartItem = {
        label: cur.label,
        value: cur.value,
      };
      const _chartItem = { ...chartItem, ...vehicles };
      acc.push(_chartItem);
      return acc;
    }, []);
};

export const randomNumberFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const convertResponseToBayUtilizationData = (
  data: BayUtilizationResponse
) => {
  if (!data) return [];
  return data.reduce((acc, cur) => {
    if ([9, 10].includes(cur.bayNumber)) return acc;
    acc.push({
      label: `Bay ${cur.bayNumber}`,
      value: `Bay ${cur.bayNumber}`,
      activeHours: cur.occupiedPercentage,
      emptyHours: 100 - cur.occupiedPercentage,
    });
    return acc;
  }, [] as any);
};

export const convertResponseToEfficiencyData = (data: EfficiencyResponse) => {
  function largestElement(arr: number[]) {
    return Math.max(...arr);
  }
  const values = data?.map((item) => item.numberOfVehicles);

  if (!data) return [];
  return data.reduce((acc, cur) => {
    if ([9, 10].includes(cur.bayId)) return acc;
    acc.push({
      label: `Bay ${cur.bayId}`,
      value: `Bay ${cur.bayId}`,
      numberOfVehicles: cur.numberOfVehicles,
      background: values ?? largestElement(values),
    });
    return acc;
  }, [] as any);
};

export const convertResponseToMaintenancByUnitTypeData = (
  data: MaintenanceByUnitTypeResponse,
  vehicleTypes: VehicleType[]
) => {
  if (!data) return [];
  return data.reduce((acc, cur) => {
    if ([9, 10].includes(cur.bayNumber)) return acc;
    acc.push({
      label: `Bay ${cur.bayNumber}`,
      value: `Bay ${cur.bayNumber}`,
      vehicles: cur.percentageByType.reduce((acc, cur) => {
        if (
          vehicleTypes.find((type) => type.vehicleTypeId === cur.vehicleType)
            ?.vehicleName
        ) {
          acc.push({
            name: vehicleTypes.find(
              (type) => type.vehicleTypeId === cur.vehicleType
            )?.vehicleName,
            activeHours: cur.percentage,
          });
        }
        return acc;
      }, [] as any),
    });
    return acc;
  }, [] as any);
};

// export const convertResponseToEfficiencyData = (
//   data: EfficiencyResponse,
//   vehicleTypes: VehicleType[]
// ) => {
//   const result = data?.reduce((acc, cur) => {
//     const vehicles = cur.occurenceByType?.reduce((acc, cur) => {
//       acc.push({
//         name: vehicleTypes?.find(
//           (item) => cur.vehicleType === item.vehicleTypeId
//         )?.vehicleName,
//         occurredInPeriod: cur.occurredInPeriod.toFixed(2),
//         color: vehicleTypesColors.find((item) => item.id === cur.vehicleType)
//           ?.color,
//       });
//       return acc;
//     }, [] as any);
//     acc.push({
//       label: `Bay ${cur.bayNumber}`,
//       value: `Bay ${cur.bayNumber}`,
//       bayValue: cur.bayNumber,
//       vehicles,
//     });
//     return acc;
//   }, [] as any);
//   return result;
// };
