import Table, { ColumnsType } from "antd/es/table";
import { Button, Modal, Tag } from "antd";
import { Vehicle, VehicleTableData } from "../../../api/types";
import { VehicleActions } from "../styled";
import { ActionModes } from "../vehiclesSettings";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useVehicleTypes, useVehicles } from "../../../hooks/vehicles-hook";

const { confirm } = Modal;

interface OwnProps {
  tableHeight: number;
  enterMode: (mode: ActionModes, selectedVehicle?: VehicleTableData) => void;
  deleteVehicle: (id: string) => void;
}

export const VehiclesTable = ({
  tableHeight,
  enterMode,
  deleteVehicle,
}: OwnProps) => {
  const { data: vehicles, isLoading } = useVehicles();
  const { data: vehicleTypes } = useVehicleTypes();

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure delete this vehicle?",
      icon: <ExclamationCircleFilled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />,
      // content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteVehicle(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<Vehicle> = [
    {
      title: "Vehicle ID",
      dataIndex: "vehicleId",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      render: (value: any) => {
        return vehicleTypes?.find((item) => item.label === value)?.label;
      },
    },
    {
      title: "Production Year",
      dataIndex: "productionYear",
      sorter: (a, b) => a.productionYear - b.productionYear,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (value: any) => {
        return value === true ? "Active" : "Not Active";
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (_, { rfidTag1, rfidTag2 }) => {
        let color = "green";
        return (
          <>
            {rfidTag1 && (
              <Tag color={color} style={{ marginBottom: "2px" }}>
                {rfidTag1}
              </Tag>
            )}
            {rfidTag2 && (
              <Tag color={color} style={{ marginBottom: "2px" }}>
                {rfidTag2}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      render: (record: VehicleTableData) => {
        return (
          <VehicleActions>
            <Button
              type="primary"
              ghost
              onClick={() => {
                enterMode(ActionModes.EDIT, record);
              }}
            >
              Edit
            </Button>
            <Button onClick={() => showDeleteConfirm(record.id)} danger>
              Delete
            </Button>
          </VehicleActions>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={vehicles}
      pagination={false}
      loading={isLoading}
      scroll={{ y: tableHeight }}
      rowKey="id"
    />
  );
};
