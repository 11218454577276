import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { FormFooter } from "../styled";
import { Dispatch, SetStateAction, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  CreateVehiclePayload,
  UpdateVehiclePayload,
  VehicleTableData,
} from "../../../api/types";
import { ActionModes } from "../vehiclesSettings";
import { useVehicleTypes } from "../../../hooks/vehicles-hook";

interface OwnProps {
  open: boolean;
  handleOk: (newVehicle: CreateVehiclePayload) => void;
  handleCancel: () => void;
  actionMode: ActionModes;
  selectedVehicle: VehicleTableData | null;
  setSelectedVehicle: Dispatch<SetStateAction<VehicleTableData | null>>;
  updateVehicle: (vehicle: UpdateVehiclePayload, id: string) => void;
}

const initialData = {
  vehicleType: -1,
  productionYear: 0,
  active: false,
  rfidTag1: "",
  rfidTag2: "",
};

export const AddEditVehicleModal = ({
  open,
  handleOk,
  handleCancel,
  actionMode,
  selectedVehicle,
  setSelectedVehicle,
  updateVehicle,
}: OwnProps) => {
  const [form] = Form.useForm();
  const { data: vehicleTypes } = useVehicleTypes();
  const [formData, setFormData] = useState(initialData);

  const fillFormData = () => {
    if (selectedVehicle) {
      const newVehicleType = vehicleTypes?.find(
        (item) => item.label === selectedVehicle.vehicleType
      )?.value as number;
      setFormData({
        ...formData,
        vehicleType: newVehicleType,
        productionYear: selectedVehicle.productionYear,
        active: selectedVehicle.active,
        rfidTag1: selectedVehicle.rfidTag1,
        rfidTag2: selectedVehicle.rfidTag2,
      });
      form.setFieldsValue({
        vehicleType: newVehicleType,
        productionYear: selectedVehicle.productionYear,
        active: selectedVehicle.active,
        rfidTag1: selectedVehicle.rfidTag1,
        rfidTag2: selectedVehicle.rfidTag2,
      });
    }
  };

  const resetFormAndData = () => {
    setFormData(initialData);
    form.setFieldsValue({
      vehicleType: "",
      productionYear: "",
      active: false,
      rfidTag1: "",
      rfidTag2: "",
    });
    handleSelection(0);
    setSelectedVehicle(null);
  };

  const onCancel = () => {
    resetFormAndData();
    handleCancel();
  };

  const handleCreate = () => {
    const newVehicle = {
      vehicleType: formData.vehicleType,
      productionYear: formData.productionYear,
      active: formData.active,
      rfidTag1: formData.rfidTag1,
      rfidTag2: formData.rfidTag2,
    };
    handleOk(newVehicle);
  };

  const handleUpdate = () => {
    const updatedVehicle = {
      ...formData,
      id: selectedVehicle?.id as string,
    };
    updateVehicle(updatedVehicle, selectedVehicle?.id as string);
  };

  const onOk = () => {
    if (actionMode === ActionModes.CREATE) {
      handleCreate();
    }
    if (actionMode === ActionModes.EDIT) {
      handleUpdate();
    }
  };

  const handleSelection = (value: number) => {
    setFormData({ ...formData, vehicleType: value });
  };

  const onChange = (e: React.ChangeEvent) => {
    const { target } = e;
    const { name, value } = target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    const { target } = e;
    const { name, checked } = target as HTMLInputElement;
    setFormData({ ...formData, [name]: checked });
  };

  return (
    <Modal
      title={`${actionMode === ActionModes.CREATE ? "Add" : "Edit"} vehicle`}
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      afterOpenChange={fillFormData}
      afterClose={resetFormAndData}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        style={{ maxWidth: 600, padding: "30px 0 0 0" }}
        onFinish={onOk}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Vehicle type"
          name="vehicleType"
          rules={[{ required: true, message: "Vehicle type is required" }]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Please select"
            onChange={handleSelection}
            options={vehicleTypes}
            value={formData.vehicleType}
          />
        </Form.Item>

        <Form.Item
          label="Production year"
          name="productionYear"
          rules={[{ required: true, message: "Production year is required" }]}
        >
          <Input
            name="productionYear"
            onChange={onChange}
            value={formData.productionYear}
          />
        </Form.Item>

        <Form.Item label="RFID Tag 1" name="rfidTag1">
          <Input
            name="rfidTag1"
            onChange={onChange}
            value={formData.rfidTag1}
          />
        </Form.Item>

        {formData.rfidTag1.length > 0 && (
          <Form.Item label="RFID Tag 2" name="rfidTag2">
            <Input
              name="rfidTag2"
              onChange={onChange}
              value={formData.rfidTag2}
            />
          </Form.Item>
        )}

        <Form.Item name="active" valuePropName="checked">
          <Checkbox
            name="active"
            onChange={onCheckboxChange}
            defaultChecked={formData.active}
          >
            Active
          </Checkbox>
        </Form.Item>

        <FormFooter>
          <Button type="ghost" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormFooter>
      </Form>
    </Modal>
  );
};
