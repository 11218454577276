import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { EfficiencyPayload, EfficiencyResponse } from "../api/types";
import { convertResponseToEfficiencyData } from "../pages/dashboard/utils";

export const fetchEfficiencyData = async (
  data: EfficiencyPayload
): Promise<EfficiencyResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/Efficiency`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useEfficiency = () => {
  const { mutate, data, isLoading, error, isSuccess } =
    useMutation(fetchEfficiencyData);

  const newData = convertResponseToEfficiencyData(data as EfficiencyResponse);

  const loading = isLoading;

  return { mutate, data: newData, isLoading: loading, error, isSuccess };
};
