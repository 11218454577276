import React, {
  createContext,
  useReducer,
  useEffect,
  ReactNode,
  Dispatch,
} from "react";
import { Alarm } from "../api/types";
import { alarmsReducer } from "../reducers/alarmsReducer";

interface OwnProps {
  children: ReactNode;
}

export type AlarmsContextType = {
  alarms: Alarm[];
  dispatch: Dispatch<any> | null;
};

export const AlarmsContext = createContext<AlarmsContextType>({
  alarms: [],
  dispatch: null,
});

export const AlarmsProvider = ({ children }: OwnProps) => {
  const [alarms, dispatch] = useReducer(alarmsReducer, "", () => {
    const localData = JSON.parse(localStorage.getItem("alarms") as string);
    return localData ? localData : "";
  });

  useEffect(() => {
    localStorage.setItem("alarms", JSON.stringify(alarms));
  }, [alarms]);

  return (
    <AlarmsContext.Provider value={{ alarms, dispatch }}>
      {children}
    </AlarmsContext.Provider>
  );
};

export default AlarmsContext;
