import { useMutation, useQuery } from "react-query";
import { BASE_URL } from "../api";
import {
  MaintenanceByUnitTypePayload,
  MaintenanceByUnitTypeResponse,
  VehicleType,
} from "../api/types";
import { convertResponseToMaintenancByUnitTypeData } from "../pages/dashboard/utils";
import { fetchVehicleTypes } from "./vehicles-hook";

export const fetchMaintenanceByUnitTypeData = async (
  data: MaintenanceByUnitTypePayload
): Promise<MaintenanceByUnitTypeResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/MaintenanceByUnitType`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useMaintenanceByUnitType = () => {
  const { data: vehicleTypes } = useQuery("vehicleTypes", fetchVehicleTypes);
  const { mutate, data, isLoading, error, isSuccess } = useMutation(
    fetchMaintenanceByUnitTypeData
  );

  const newData = convertResponseToMaintenancByUnitTypeData(
    data as MaintenanceByUnitTypeResponse,
    vehicleTypes as VehicleType[]
  );

  return { mutate, data: newData, isLoading, error, isSuccess };
};
