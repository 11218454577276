import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  blue,
  green,
  orange,
  purple,
  yellow,
  red,
  volcano,
} from "@ant-design/colors";
import {
  FiltersCol,
  FiltersContainer,
  FiltersFormItem,
  SelectedDateWrapper,
  StyledDivider,
} from "../../styled";
import { Button, Form, Modal, Select, Typography } from "antd";
import {
  camelize,
  convertDataToMaintenanceByUnitTypeChart,
  getVehiclesFromMaintenanceByUnitTypeChartData,
} from "../../utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormFooter } from "../../../vehicles/styled";
import { useMaintenanceByUnitType } from "../../../../hooks/maintenanceByUnitTypeHook";
import { bayUtilizationChartMockData } from "./mock/chartsMock";
import dayjs from "dayjs";
import { StyledSpin } from "../../../../styled";
import { CustomRangePicker } from "../CustomRangePicker";

const { Option } = Select;

const colors = [
  blue.primary,
  orange.primary,
  green.primary,
  purple.primary,
  yellow.primary,
  red.primary,
  volcano.primary,
];

interface OwnProps {
  chartHeight: number;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  selectedStartDate: number;
  setSelectedStartDate: Dispatch<SetStateAction<number>>;
  selectedEndDate: number;
  setSelectedEndDate: Dispatch<SetStateAction<number>>;
  filtersSumitted: boolean;
  setFiltersSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const MaintenanceByUnitTypeChart = ({
  chartHeight,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  filtersSumitted,
  setFiltersSubmitted,
}: OwnProps) => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const { mutate, data, isSuccess, isLoading } = useMaintenanceByUnitType();
  const [selectedBays, setSelectedBays] = useState(bayUtilizationChartMockData);
  const [bays, setBays] = useState(
    convertDataToMaintenanceByUnitTypeChart(data),
  );
  const [vehicles, setVehicles] = useState(
    getVehiclesFromMaintenanceByUnitTypeChartData(data),
  );

  useEffect(() => {
    mutate({
      bays: selectedBays.map((bay: any) => bay.bayValue),
      fromInUnixSeconds: selectedStartDate,
      toInUnixSeconds: selectedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setBays(convertDataToMaintenanceByUnitTypeChart(data));
      setVehicles(getVehiclesFromMaintenanceByUnitTypeChartData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (filtersSumitted === true) {
      mutate({
        bays: selectedBays.map((bay: any) => bay.bayValue),
        fromInUnixSeconds: selectedStartDate,
        toInUnixSeconds: selectedEndDate,
      });
      setFiltersSubmitted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSumitted])

  const handleChange = (value: string[]) => {
    if (value.includes("all")) {
      setSelectedBays(bayUtilizationChartMockData);
      return;
    }
    setSelectedBays(
      bayUtilizationChartMockData.filter((item) => value.includes(item.label)),
    );
  };

  const openFiltersModal = () => {
    setFiltersModalOpen(true);
  };

  const closeFiltersModal = () => {
    setFiltersModalOpen(false);
  };

  const onFiltersSubmit = () => {
    setFiltersSubmitted(true)
    closeFiltersModal();
  };

  const YAxisFormatter = (value: string) => `${value}%`;

  return (
    <>
      <FiltersContainer>
        <Modal
          title="Filters"
          open={filtersModalOpen}
          onCancel={closeFiltersModal}
          footer={null}
          destroyOnClose
          // afterOpenChange={fillFormData}
          // afterClose={resetFormAndData}
        >
          <Form layout="vertical" onFinish={onFiltersSubmit}>
            <FiltersFormItem label="Select bays">
              <Select
                style={{ width: "100%" }}
                placeholder="Please select bays"
                onChange={handleChange}
                mode="multiple"
                allowClear
                onClear={() => setBays([])}
                value={selectedBays.map((bay) => bay.value)}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {bayUtilizationChartMockData.map((option: any) => (
                  <Option key={option.value} value={option.value}>
                    {option.value}
                  </Option>
                ))}
              </Select>
            </FiltersFormItem>
            <FiltersFormItem label="Select date range">
              <CustomRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
              />
            </FiltersFormItem>
            <FormFooter>
              <Button type="ghost" onClick={closeFiltersModal}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormFooter>
          </Form>
        </Modal>
        <FiltersCol>
          <Typography.Title style={{ margin: 0 }} level={3}>
            Bay Occupancy by Unit Type
          </Typography.Title>
        </FiltersCol>
        <FiltersCol>
          <Button
            onClick={openFiltersModal}
            style={{ marginLeft: "auto", display: "block" }}
          >
            Filters
          </Button>
        </FiltersCol>
      </FiltersContainer>
      <SelectedDateWrapper>
        <span>
          {dayjs(startDate).format("MMM DD, YYYY")} -{" "}
          {dayjs(endDate).format("MMM DD, YYYY")}
        </span>
      </SelectedDateWrapper>
      <StyledDivider />
      {isLoading && <StyledSpin />}
      {!isLoading && (
        <ResponsiveContainer width={"100%"} height={chartHeight}>
          <BarChart width={500} height={300} data={bays}>
            <Tooltip />
            {vehicles.map((vehicle: any, index: number) => {
              return (
                <Bar
                  dataKey={camelize(vehicle.label)}
                  stackId="a"
                  fill={colors[index]}
                  name={vehicle.label}
                  label={({ x, y, width, height, index }) => {
                    const value = bays[index][camelize(vehicle.label)];

                    const dx = width / 2;
                    const dy = height / 2;

                    return (
                      <text
                        x={x}
                        y={y}
                        dx={dx}
                        dy={dy}
                        fill="#ffffff"
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        {value > 0 && `${value}%`}
                      </text>
                    );
                  }}
                />
              );
            })}
            <XAxis dataKey="label" />
            <YAxis domain={[0, 100]} tickFormatter={YAxisFormatter} />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
