import { InfoBox, StyledVehicleInfoItem } from "../styled";
import { Typography } from "antd";
import { OverviewVehicle } from "../../../api/types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { useConvertTimeFormat } from "../../../hooks/useConvertTime";

interface OwnProps {
  vehicle: OverviewVehicle;
}

export const VehicleInfoItem = ({ vehicle }: OwnProps) => {
  const { type, vehicleId } = vehicle;
  const convertTimeFormat = useConvertTimeFormat(vehicle.duration);

  return (
    <StyledVehicleInfoItem>
      <InfoBox>
        <Typography.Paragraph
          style={{ marginBottom: 2, fontSize: 16, fontWeight: "bold" }}
        >
          {type === "Empty" ? "This bay is empty" : type}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: 14, marginBottom: 2 }}>
          <span style={{ fontWeight: 700 }}>{vehicleId ? vehicleId : ""}</span>
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: 12, margin: 0 }}>
          {convertTimeFormat}
        </Typography.Paragraph>
      </InfoBox>
    </StyledVehicleInfoItem>
  );
};
