import { useQuery } from "react-query";
import { BASE_URL } from "../api";
import { Bay } from "../api/types";
import { useMemo } from "react";

export const fetchBays = async (): Promise<Bay[]> => {
  const res = await fetch(`${BASE_URL}/MCS/Overview`);
  return res.json();
};

export const useBays = () => {
  const { data, isLoading, error, refetch } = useQuery("bays", fetchBays);

  const filteredData = useMemo(() => {
    return data
      ?.filter((bay) => ![9, 10].includes(bay.lane))
      .map((bay) => {
        return bay;
      });
  }, [data]);

  return { data: filteredData, isLoading, error, refetch };
};
