import { useMutation, useQuery, useQueryClient } from "react-query";
import { BASE_URL } from "../api";
import {
  CreateVehiclePayload,
  UpdateVehiclePayload,
  Vehicle,
  VehicleType,
} from "../api/types";
import {
  convertVehicleTypesToSelectOptions,
  convertVehiclesToTableData,
} from "../pages/vehicles/utils";

const fetchVehicles = async (): Promise<Vehicle[]> => {
  const res = await fetch(`${BASE_URL}/Vehicle`);
  return res.json();
};

const addVehicle = async (vehicle: CreateVehiclePayload) => {
  const res = await fetch(`${BASE_URL}/Vehicle`, {
    method: "POST",
    body: JSON.stringify(vehicle),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.json();
};

const updateVehicle = async (vehicleData: {
  vehicle: UpdateVehiclePayload;
  vehicleId: string;
}) => {
  const res = await fetch(`${BASE_URL}/Vehicle/${vehicleData.vehicleId}`, {
    method: "PUT",
    body: JSON.stringify(vehicleData.vehicle),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.json();
};

const deleteVehicle = async (vehicleId: string) => {
  const res = await fetch(`${BASE_URL}/Vehicle/${vehicleId}`, {
    method: "DELETE",
  });
  return res.json();
};

export const fetchVehicleTypes = async (): Promise<VehicleType[]> => {
  const res = await fetch(`${BASE_URL}/Vehicle/vehicleTypes`);
  return res.json();
};

export const useVehicles = () => {
  const { data, isLoading, error, refetch } = useQuery(
    // query key
    "vehicles",
    fetchVehicles,
    {
      select: (data) => convertVehiclesToTableData(data),
    }
  );
  return { data, isLoading, error, refetch };
};

export const useAddVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(addVehicle, {
    onSettled: () => {
      // using query key to refetch vehicles once new vehicle is created
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
    },
  });
};

export const useUpdateVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(updateVehicle, {
    onSettled: () => {
      // using query key to refetch vehicles once the vehicle is updated
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
    },
  });
};

export const useDeleteVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteVehicle, {
    onSettled: () => {
      // using query key to refetch vehicles once the vehicle is deleted
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
    },
  });
};

export const useVehicleTypes = () => {
  const { data, isLoading, error, refetch } = useQuery(
    "vehicleTypes",
    fetchVehicleTypes,
    {
      select: (data: VehicleType[]) => {
        return convertVehicleTypesToSelectOptions(data);
      },
    }
  );
  return { data, isLoading, error, refetch };
};
