import dayjs from "dayjs";
import { Alarm } from "../api/types";
import { DATE_FORMAT } from "../pages/dashboard/utils";

export const alarmsMock: Alarm[] = [
  {
    id: "1",
    alarmType: "alarm type 1",
    timestamp: 101,
    acknowledgeTime: dayjs(Date.now()).format(DATE_FORMAT),
    isAcknowledged: true,
  },
  {
    id: "2",
    alarmType: "alarm type 2",
    timestamp: 102,
    acknowledgeTime: dayjs(Date.now()).format(DATE_FORMAT),
    isAcknowledged: true,
  },
  {
    id: "3",
    alarmType: "alarm type 3",
    timestamp: 103,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "4",
    alarmType: "alarm type 4",
    timestamp: 104,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "5",
    alarmType: "alarm type 5",
    timestamp: 105,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "6",
    alarmType: "alarm type 6",
    timestamp: 106,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "7",
    alarmType: "alarm type 7",
    timestamp: 107,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "8",
    alarmType: "alarm type 8",
    timestamp: 108,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "9",
    alarmType: "alarm type 9",
    timestamp: 109,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "10",
    alarmType: "alarm type 10",
    timestamp: 110,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "11",
    alarmType: "alarm type 11",
    timestamp: 111,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "12",
    alarmType: "alarm type 12",
    timestamp: 112,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "13",
    alarmType: "alarm type 13",
    timestamp: 113,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "14",
    alarmType: "alarm type 14",
    timestamp: 114,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "15",
    alarmType: "alarm type 15",
    timestamp: 115,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
  {
    id: "16",
    alarmType: "alarm type 16",
    timestamp: 116,
    acknowledgeTime: "",
    isAcknowledged: false,
  },
];
