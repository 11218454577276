import { MaintenanceBay } from "../api/types";

export const baysMock: MaintenanceBay[] = [
  {
    id: "1",
    name: "Bay 1",
    cameraId: "1",
    active: true,
  },
  {
    id: "2",
    name: "Bay 2",
    cameraId: "2",
    active: true,
  },
  {
    id: "3",
    name: "Bay 3",
    cameraId: "3",
    active: true,
  },
  {
    id: "4",
    name: "Bay 4",
    cameraId: "4",
    active: true,
  },
  {
    id: "5",
    name: "Bay 5",
    cameraId: "5",
    active: false,
  },
  {
    id: "6",
    name: "Bay 6",
    cameraId: "6",
    active: false,
  },
  {
    id: "7",
    name: "Bay 7",
    cameraId: "7",
    active: false,
  },
  {
    id: "8",
    name: "Bay 8",
    cameraId: "8",
    active: false,
  },
  // {
  //   id: "9",
  //   name: "Bay 9",
  //   cameraId: "9",
  //   active: false,
  // },
  // {
  //   id: "10",
  //   name: "Bay 10",
  //   cameraId: "10",
  //   active: false,
  // },
  {
    id: "11",
    name: "Bay 11",
    cameraId: "11",
    active: false,
  },
  {
    id: "12",
    name: "Bay 12",
    cameraId: "12",
    active: false,
  },
  {
    id: "13",
    name: "Bay 13",
    cameraId: "13",
    active: false,
  },
  {
    id: "14",
    name: "Bay 14",
    cameraId: "14",
    active: false,
  },
  {
    id: "15",
    name: "Bay 15",
    cameraId: "15",
    active: false,
  },
  {
    id: "16",
    name: "Bay 16",
    cameraId: "16",
    active: false,
  },
  {
    id: "17",
    name: "Bay 17",
    cameraId: "17",
    active: false,
  },
  {
    id: "18",
    name: "Bay 18",
    cameraId: "18",
    active: false,
  },
];
