import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../../../constants";

interface LayoutProps {
  windowHeight: number;
}
interface LayoutContentProps {
  checkAuth: boolean;
  isMobileView: boolean;
}

interface LayoutContentWrapperProps {
  isMobileView: boolean;
}

export const Layout = styled.div<LayoutProps>`
  display: flex;
  height: ${({ windowHeight }) => `${windowHeight}px`};
  overflow: hidden;
`;

export const LayoutContent = styled.div<LayoutContentProps>`
  overflow: hidden;
  margin-left: ${({ checkAuth, isMobileView }) =>
    isMobileView ? "0" : checkAuth ? `${SIDEBAR_WIDTH}px` : 0};
  width: 100%;
  background-color: #fff;
  padding: ${({ checkAuth, isMobileView }) =>
    isMobileView ? "0" : checkAuth ? `25px 25px 25px 0` : `0`};
  display: flex;
`;

export const LayoutContentWrapper = styled.div<LayoutContentWrapperProps>`
  overflow: hidden;
  background-color: #fff;
  padding-left: ${({ isMobileView }) => (isMobileView ? "0" : "25px")};
  width: 100%;
`;
