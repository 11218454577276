import { useEffect, useRef, useState } from "react";

export const useTrackOffset = () => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [bodyWidth, setBodyWidth] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);

  const trackOffset = () => {
    const { current } = containerRef;
    if (!!current) {
      setContainerWidth((current as HTMLDivElement)?.offsetWidth);
      setContainerHeight((current as HTMLDivElement)?.offsetHeight);
    }
    if (document.body) {
      setBodyWidth(document.body.offsetWidth);
      setBodyHeight(document.body.offsetHeight);
    }
  };

  useEffect(() => {
    trackOffset();
    window.addEventListener("resize", trackOffset);

    return () => {
      window.removeEventListener("resize", trackOffset);
    };
  }, []);

  return {
    containerRef,
    containerWidth,
    containerHeight,
    bodyWidth,
    bodyHeight,
  };
};
