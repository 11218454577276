import React, {
  createContext,
  useReducer,
  useEffect,
  ReactNode,
  Dispatch,
} from "react";
import { MaintenanceBay } from "../api/types";
import { maintenanceBaysReducer } from "../reducers/maintenanceBaysReducer";

interface OwnProps {
  children: ReactNode;
}

export type MaintenanceBaysContextType = {
  maintenanceBays: MaintenanceBay[];
  dispatch: Dispatch<any> | null;
};

export const MaintenanceBaysContext = createContext<MaintenanceBaysContextType>(
  {
    maintenanceBays: [],
    dispatch: null,
  }
);

export const MaintenanceBaysProvider = ({ children }: OwnProps) => {
  const [maintenanceBays, dispatch] = useReducer(
    maintenanceBaysReducer,
    "",
    () => {
      const localData = JSON.parse(
        localStorage.getItem("maintenanceBays") as string
      );
      return localData ? localData : "";
    }
  );

  useEffect(() => {
    localStorage.setItem("maintenanceBays", JSON.stringify(maintenanceBays));
  }, [maintenanceBays]);

  return (
    <MaintenanceBaysContext.Provider value={{ maintenanceBays, dispatch }}>
      {children}
    </MaintenanceBaysContext.Provider>
  );
};

export default MaintenanceBaysContext;
