import dayjs, { Dayjs } from "dayjs";

export const bayUtilizationChartMockData = [
  {
    label: "Bay 1",
    value: "Bay 1",
    bayValue: 1,
  },
  {
    label: "Bay 2",
    value: "Bay 2",
    bayValue: 2,
  },
  {
    label: "Bay 3",
    value: "Bay 3",
    bayValue: 3,
  },
  {
    label: "Bay 4",
    value: "Bay 4",
    bayValue: 4,
  },
  {
    label: "Bay 5",
    value: "Bay 5",
    bayValue: 5,
  },
  {
    label: "Bay 6",
    value: "Bay 6",
    bayValue: 6,
  },
  {
    label: "Bay 7",
    value: "Bay 7",
    bayValue: 7,
  },
  {
    label: "Bay 8",
    value: "Bay 8",
    bayValue: 8,
  },
  // {
  //   label: "Bay 9",
  //   value: "Bay 9",
  //   bayValue: 9,
  // },
  // {
  //   label: "Bay 10",
  //   value: "Bay 10",
  //   bayValue: 10,
  // },
  {
    label: "Bay 11",
    value: "Bay 11",
    bayValue: 11,
  },
  {
    label: "Bay 12",
    value: "Bay 12",
    bayValue: 12,
  },
  {
    label: "Bay 13",
    value: "Bay 13",
    bayValue: 13,
  },
  {
    label: "Bay 14",
    value: "Bay 14",
    bayValue: 14,
  },
  {
    label: "Bay 15",
    value: "Bay 15",
    bayValue: 15,
  },
  {
    label: "Bay 16",
    value: "Bay 16",
    bayValue: 16,
  },
  {
    label: "Bay 17",
    value: "Bay 17",
    bayValue: 17,
  },
  {
    label: "Bay 18",
    value: "Bay 18",
    bayValue: 18,
  },
];

export interface maintenanceByUnitTypeChartVehicle {
  name: string;
  activeHours: number;
}

export interface MaintenanceByUnitTypeChartItem {
  label: string;
  value: string;
  vehicles: maintenanceByUnitTypeChartVehicle[];
}

export const maintenanceByUnitTypeChartMockData: MaintenanceByUnitTypeChartItem[] =
  [
    {
      label: "Bay 1",
      value: "Bay 1",
      vehicles: [
        {
          name: "Frac Pump",
          activeHours: 50,
        },
        {
          name: "Blender",
          activeHours: 42,
        },
      ],
    },
    {
      label: "Bay 2",
      value: "Bay 2",
      vehicles: [
        {
          name: "Frac Pump",
          activeHours: 75,
        },
        {
          name: "Blender",
          activeHours: 25,
        },
      ],
    },
    {
      label: "Bay 3",
      value: "Bay 3",
      vehicles: [
        {
          name: "Frac Pump",
          activeHours: 8,
        },
        {
          name: "Blender",
          activeHours: 67,
        },
        {
          name: "Truck",
          activeHours: 10,
        },
      ],
    },
  ];

export const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  {
    label: "Today",
    value: [dayjs().startOf("d"), dayjs()],
  },
  {
    label: "Yesterday",
    value: [dayjs().add(-1, "d").startOf("d"), dayjs().add(-1, "d").endOf("d")],
  },
  { label: "Last 7 Days", value: [dayjs().add(-7, "d").startOf("d"), dayjs()] },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d").startOf("d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d").startOf("d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d").startOf("d"), dayjs()],
  },
];

export const efficiencyChartData = [
  {
    label: "Bay 1",
    value: "Bay 1",
    bayValue: 1,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 2",
    value: "Bay 2",
    bayValue: 2,
    customDate: "8",
    pastWeek: "7.1",
    pastMonth: "6.5",
  },
  {
    label: "Bay 3",
    value: "Bay 3",
    bayValue: 3,
    customDate: "4",
    pastWeek: "3.4",
    pastMonth: "3.8",
  },
  {
    label: "Bay 4",
    value: "Bay 4",
    bayValue: 4,
    customDate: "2",
    pastWeek: "3",
    pastMonth: "4.4",
  },
  {
    label: "Bay 5",
    value: "Bay 5",
    bayValue: 5,
    customDate: "2.8",
    pastWeek: "3.8",
    pastMonth: "4",
  },
  {
    label: "Bay 6",
    value: "Bay 6",
    bayValue: 6,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 7",
    value: "Bay 7",
    bayValue: 7,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 8",
    value: "Bay 8",
    bayValue: 8,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 9",
    value: "Bay 9",
    bayValue: 9,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 10",
    value: "Bay 10",
    bayValue: 10,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 11",
    value: "Bay 11",
    bayValue: 11,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 12",
    value: "Bay 12",
    bayValue: 12,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 13",
    value: "Bay 13",
    bayValue: 13,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 14",
    value: "Bay 14",
    bayValue: 14,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 15",
    value: "Bay 15",
    bayValue: 15,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 16",
    value: "Bay 16",
    bayValue: 16,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 17",
    value: "Bay 17",
    bayValue: 17,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
  {
    label: "Bay 18",
    value: "Bay 18",
    bayValue: 18,
    customDate: "6",
    pastWeek: "5.2",
    pastMonth: "5.1",
  },
];
