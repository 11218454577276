import { LiveViewPageHeaderButton, StyledLiveViewPageHeader } from "../styled";
import { Typography } from "antd";
import useDevice from "../../../hooks/useDevice";
import view3d from "../../../assets/svg/view3d.svg";
import Icon from "@ant-design/icons";

interface OwnProps {
  totalBays: number;
  showModal: () => void;
  activeBays: number;
}

export const LiveViewPageHeader = ({
  totalBays,
  showModal,
  activeBays,
}: OwnProps) => {
  const { isMobileView } = useDevice();

  return (
    <StyledLiveViewPageHeader isMobileView={isMobileView}>
      <Typography.Title level={3} style={{ fontWeight: "bold" }}>
        Live View: {activeBays}
        {activeBays && "/"}
        {totalBays}
      </Typography.Title>

      {!isMobileView && (
        <a
          href="https://propetro-3d.meteredapps.com/"
          target="_blank"
          rel="noreferrer"
        >
          <LiveViewPageHeaderButton type="primary">
            <Icon
              component={() => <img src={view3d} alt={"view3d icon"} />}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            3D View
          </LiveViewPageHeaderButton>
        </a>
      )}
    </StyledLiveViewPageHeader>
  );
};
