import { Alert, Spin } from "antd";
import styled from "styled-components";

export const boxShadowMixin = `
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);  
`;

export const StyledAlert = styled(Alert)`
  position: fixed;
  top: 0;
  right: 0;
`;

export const StyledSpin = styled(Spin)`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
