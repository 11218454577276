import styled from "styled-components";

interface PageContentProps {
  isMobileView?: boolean;
}

export const PageContent = styled.div<PageContentProps>`
  padding-right: ${({ isMobileView }) => (isMobileView ? "0" : "25px")};
  padding-bottom: ${({ isMobileView }) => (isMobileView ? "0" : "25px")};
  height: 100%;
`;
