import React from "react";
import {VehicleItem} from "./VehicleItem";
import {StyledDuration, VehiclesContainer} from "../styled";
import {OverviewVehicle} from "../../../api/types";
import useDevice from "../../../hooks/useDevice";
import {useConvertTime} from "../../../hooks/useConvertTime";

interface OwnProps {
  vehicles: OverviewVehicle[];
}

export const Vehicles = ({vehicles}: OwnProps) => {
  const {isMobileView} = useDevice();
  const convertedTime = useConvertTime(vehicles)

  return (
    <VehiclesContainer isMobileView={isMobileView}>
      {vehicles?.length === 0 ? (
        <VehicleItem name="Empty"/>
      ) : (
        <>
          {vehicles?.map((vehicle) => (
            <VehicleItem key={vehicle.vehicleId} name={vehicle.type}/>
          ))}
          <StyledDuration>{convertedTime}</StyledDuration>
        </>
      )}
    </VehiclesContainer>
  );
};
