import styled from "styled-components";
import {Button, Form, Typography} from "antd";

export const CustomLoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #F1F1F1;
`;

export const LoginContainer = styled.div`
  padding: 40px;
  border-radius: 10px;
  background-color: transparent;
`;

export const FormTitle = styled(Typography.Title)`
  margin-bottom: 20px !important;
`

export const StyledFormItem = styled(Form.Item)`
  width: 100%;
  position: relative;
  height: 30px;
  
  label {
    color: #fff !important;
  }
  
  svg {
    fill: #1890FF;
  }
  
`;

export const LoginButton = styled(Button)`
  color: #fff;
  border-radius: 4px;
  width: 114px;
  height: 40px;
  margin-top: 10px;
`;
