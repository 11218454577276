import { StyledVehicleItem } from "../styled";
import useDevice from "../../../hooks/useDevice";

interface OwnProps {
  name: string;
}

export const VehicleItem = ({ name }: OwnProps) => {
  const { isMobileView } = useDevice();

  return (
    <StyledVehicleItem name={name} isMobileView={isMobileView}>{name}</StyledVehicleItem>
  );
};
