import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { checkAuth } from "./utils/checkAuth";
import { IUser } from "../contexts/AuthContext";

export const RequireAuth = () => {
  const { auth } = useAuth();

  const location = useLocation();

  return checkAuth(auth as IUser) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
