import { useState } from "react";
import { Switch } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { Alarm, AlarmTableData } from "../../../api/types";
import dayjs from "dayjs";
import useAlarms from "../../../hooks/useAlarms";
import { DATE_FORMAT } from "../../dashboard/utils";

interface OwnProps {
  containerHeight: number;
}

export const AlarmsTable = ({ containerHeight }: OwnProps) => {
  const { alarms, dispatch } = useAlarms();
  const [loading, setLoading] = useState<boolean>(false);
  const tableHeight = containerHeight - 170;

  const onChange = (checked: boolean, record: AlarmTableData) => {
    setLoading(true);
    setTimeout(() => {
      const _alarms = [...alarms];
      const changedAlarm = _alarms.find((alarm) => alarm.id === record.id);
      (changedAlarm as AlarmTableData).isAcknowledged = checked;
      (changedAlarm as AlarmTableData).acknowledgeTime = dayjs(
        Date.now()
      ).format(DATE_FORMAT);
      dispatch?.({
        type: "SET_ALARMS",
        value: _alarms,
      });
      setLoading(false);
    }, 400);
  };

  const columns: ColumnsType<Alarm> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Alarm Type",
      dataIndex: "alarmType",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.timestamp - b.timestamp,
    },
    {
      title: "Acknowledge Time",
      dataIndex: "acknowledgeTime",
      onFilter: (value: any, record) => {
        if (value === "acknowledged") {
          return record.isAcknowledged;
        }
        if (value === "not_acknowledged") {
          return !record.isAcknowledged;
        }
        return true;
      },
      filters: [
        {
          text: "Acknowledged",
          value: "acknowledged",
        },
        {
          text: "Not Acknowledged",
          value: "not_acknowledged",
        },
      ],
      render: (value: any, record: Alarm) => {
        return record.isAcknowledged ? value : "";
      },
    },
    {
      title: "Acknowledged",
      render: (record: AlarmTableData) => (
        <Switch
          defaultChecked={record.isAcknowledged}
          onChange={(e) => onChange(!record.isAcknowledged, record)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={alarms}
      pagination={false}
      loading={loading}
      scroll={{ y: tableHeight }}
    />
  );
};
