import { Typography } from "antd";
import { PageContent } from "../styled";
import { AlarmsTable } from "./components/AlarmsTable";
import { useTrackOffset } from "../../hooks/trackOffsetHook";

export const AlarmsPage = () => {
  const { containerRef, containerHeight } = useTrackOffset();

  return (
    <PageContent ref={containerRef}>
      <Typography.Title style={{ margin: 0, padding: "20px 0" }} level={3}>
        Alarms
      </Typography.Title>
      <AlarmsTable containerHeight={containerHeight} />
    </PageContent>
  );
};
