import { SearchableList } from "./SearchableList";
import { StyledLiveViewPageBody } from "../styled";
import { ItemInfo } from "./ItemInfo";
import { SearchableListItemType } from "../../../api/types";
import useDevice from "../../../hooks/useDevice";

interface OwnProps {
  bays: SearchableListItemType[];
  selectedBay: number;
  handleItemSelection: (value: number) => void;
  onSearch: (value: string) => void;
  loading: boolean;
}

export const LiveViewPageBody = ({
  bays,
  selectedBay,
  handleItemSelection,
  onSearch,
  loading,
}: OwnProps) => {
  const { isMobileView } = useDevice();

  return (
    <StyledLiveViewPageBody isMobileView={isMobileView}>
      <SearchableList
        items={bays}
        selectedItem={selectedBay}
        handleItemSelection={handleItemSelection}
        onSearch={onSearch}
        loading={loading}
        searchPlaceholder="Search for a maintenance bay number"
        itemPrefix="Bay"
      />
      <ItemInfo
        items={bays}
        selectedItem={selectedBay}
        handleItemSelection={handleItemSelection}
        itemPrefix="Bay"
      />
    </StyledLiveViewPageBody>
  );
};
