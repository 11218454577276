import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { RequireAuth } from "./components/RequireAuth";
import { AppLayout } from "./components/layout/Layout";
import { AlarmsPage } from "./pages/alarms";
import { LiveViewPage } from "./pages/live";
import { LoginPage } from "./pages/login";
import { MissingPage } from "./pages/missing";
import { UnauthorizedPage } from "./pages/unauthorized";
import useAlarms from "./hooks/useAlarms";
import { useEffect } from "react";
import { convertAlarmsToTableData } from "./pages/alarms/utils";
import { alarmsMock } from "./mocks/alarmsMock";
import { SettingsPage } from "./pages/settings";

import { isIE } from "react-device-detect";
import { ReportsPage } from "./pages/dashboard";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

function App() {
  const { alarms, dispatch: dispatchAlarms } = useAlarms();

  useEffect(() => {
    dispatchAlarms?.({
      type: "SET_ALARMS",
      value: alarms.length > 0 ? alarms : convertAlarmsToTableData(alarmsMock),
    });
  }, [alarms, dispatchAlarms]);

  const routes = () => {
    return (
      <>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          {/* Protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/alarms" element={<AlarmsPage />} />
            <Route path="/live" element={<LiveViewPage />} />
            {/* <Route path="/vehicles" element={<VehiclesLiveView />} /> */}
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            {/* Catch all */}
            <Route path="*" element={<MissingPage />} />
          </Route>
        </Routes>
      </>
    );
  };

  if (isIE) return <>IE is not supported. Download Chrome/Firefox/Opera</>;

  return (
    <QueryClientProvider client={queryClient}>
      <AppLayout children={routes()} />
    </QueryClientProvider>
  );
}

export default App;
