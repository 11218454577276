import React from "react";
import { StyledLinkAlert } from "../styled";

interface OwnProps {
  value: number;
}

export const LinkAlert = ({ value }: OwnProps) => {
  return <StyledLinkAlert>{value}</StyledLinkAlert>;
};
