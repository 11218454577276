import styled from "styled-components";

export const VehiclesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VehicleActions = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  h4 {
    margin: 0 !important;
  }
`;
