import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { BayUtilizationPayload, BayUtilizationResponse } from "../api/types";
import { convertResponseToBayUtilizationData } from "../pages/dashboard/utils";

export const fetchBayUtilizationData = async (
  data: BayUtilizationPayload
): Promise<BayUtilizationResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/BayUtiilization`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useBayUtilization = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchBayUtilizationData
  );

  const newData = convertResponseToBayUtilizationData(
    data as BayUtilizationResponse
  );

  return { mutate, data: newData, isLoading, error };
};
