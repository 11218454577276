import {Form, Input} from "antd";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {checkAuth} from "../../components/utils/checkAuth";
import {IUser} from "../../contexts/AuthContext";
import useAuth from "../../hooks/useAuth";
import {
  CustomLoginPage, FormTitle,
  LoginButton,
  LoginContainer,
  StyledFormItem,
} from "./styled";
import {LockOutlined, UserOutlined} from "@ant-design/icons";


export const LoginPage = () => {
  const {auth, dispatch} = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/live";

  const onFinish = (values: any) => {
    if (!checkAuth(values)) alert("Incorrect credentials");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch?.({type: "SET_AUTH", value: values});
    }, 2000);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (checkAuth(auth as IUser)) {
      navigate(from, {replace: true});
    }
  }, [auth, from, navigate]);

  return (
    <CustomLoginPage>
      <LoginContainer>
        <FormTitle level={4}>MTI</FormTitle>
        <Form
          name="basic"
          style={{minWidth: 300, maxWidth: 600, display: "flex", flexDirection: "column", alignItems: "flex-start"}}
          initialValues={{remember: true}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <StyledFormItem
            name="email"
            rules={[{message: "Please insert your e-mail!"}]}
          >
            <Input placeholder="e-mail" prefix={
              <UserOutlined
                className="site-form-item-icon"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />}
            />
          </StyledFormItem>

          <StyledFormItem
            name="password"
            rules={[{message: "Please insert your password!"}]}
          >
            <Input.Password placeholder="password" prefix={
              <LockOutlined
                className="site-form-item-icon"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />}
            />
          </StyledFormItem>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoginButton type="primary" htmlType="submit" loading={loading}>
              Login
            </LoginButton>
          </Form.Item>
        </Form>
      </LoginContainer>
    </CustomLoginPage>
  );
};
