import { PageContent } from "../styled";
import { Tabs, TabsProps, Typography } from "antd";
import { BayUtilizationChart } from "./components/charts/BayUtilizationChart";
import { MaintenanceByUnitTypeChart } from "./components/charts/MaintenanceByUnitTypeChart";
import { useTrackOffset } from "../../hooks/trackOffsetHook";
import { EfficiencyChart } from "./components/charts/EfficiencyChart";
import { useState } from "react";
import dayjs from "dayjs";

export const ReportsPage = () => {
  const { containerRef, containerHeight } = useTrackOffset();
  const [startDate, setStartDate] = useState(
    dayjs().add(-7, "day").startOf("d").format(),
  );
  const [endDate, setEndDate] = useState(dayjs().format());
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().add(-7, "day").startOf("d").unix(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs().unix());
  const [filtersSubmitted, setFiltersSubmitted] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Bay Utilization",
      children: (
        <BayUtilizationChart
          chartHeight={containerHeight - 330}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filtersSumitted={filtersSubmitted}
          setFiltersSubmitted={setFiltersSubmitted}
        />
      ),
    },
    {
      key: "2",
      label: "Bay Occupancy by Unit Type",
      children: (
        <MaintenanceByUnitTypeChart
          chartHeight={containerHeight - 330}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filtersSumitted={filtersSubmitted}
          setFiltersSubmitted={setFiltersSubmitted}
        />
      ),
    },
    {
      key: "3",
      label: "Bay Vehicle Count",
      children: (
        <EfficiencyChart
          chartHeight={containerHeight - 330}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filtersSumitted={filtersSubmitted}
          setFiltersSubmitted={setFiltersSubmitted}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Maintenance Backlog",
    //   children: <MaintenanceBacklogChart chartHeight={containerHeight - 330} />,
    // },
  ];

  return (
    <PageContent ref={containerRef}>
      <Typography.Title
        style={{ margin: 0, padding: "20px 0", fontWeight: "bold" }}
        level={3}
      >
        Reports
      </Typography.Title>
      <div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </PageContent>
  );
};
