import React, {
  createContext,
  useReducer,
  useEffect,
  ReactNode,
  Dispatch,
} from "react";
import { authReducer } from "../reducers/authReducer";

interface OwnProps {
  children: ReactNode;
}

export interface IUser {
  email: string;
  password: string;
}

export type AuthContextType = {
  auth: IUser | null;
  dispatch: Dispatch<any> | null;
};

export const AuthContext = createContext<AuthContextType>({
  auth: null,
  dispatch: null,
});

export const AuthProvider = ({ children }: OwnProps) => {
  const [auth, dispatch] = useReducer(authReducer, "", () => {
    const localData = JSON.parse(localStorage.getItem("auth") as string);
    return localData ? localData : "";
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
