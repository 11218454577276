import { useEffect } from "react";
import { useAlarm } from "../../hooks/alarmsHook";
import useDevice from "../../hooks/useDevice";
import { SidebarLink } from "./components/SidebarLink";
import { Sidebar, SidebarNav, SidebarNavWrapper, SidebarTitle } from "./styled";
import live from "../../assets/svg/live.svg";
import reports from "../../assets/svg/reports.svg";
import signOut from "../../assets/svg/logout.svg";

export const AppSidebar = () => {
  const { isMobileView } = useDevice();
  const { data: alarmData, refetch: refetchAlarm } = useAlarm();

  const logout = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchAlarm();
    }, 60000);

    return () => {
      clearInterval(refetchInterval);
    };
  }, [refetchAlarm]);

  if (isMobileView) return null;

  return (
    <Sidebar>
      <SidebarTitle level={4}>ProPetro</SidebarTitle>
      <SidebarNavWrapper>
        <SidebarNav>
          {/* <SidebarLink to="/alarms" text="Alerts" alertValue={alarmData} /> */}
          <SidebarLink to="/live" src={live} text="Live View" />
          {/* <SidebarLink to="/vehicles" text="Vehicles" /> */}
          <SidebarLink to="/reports" src={reports} text="Reports" />
          {/* <SidebarLink to="/settings" text="Settings" /> */}
        </SidebarNav>
        <SidebarNav>
          <SidebarLink
            to="/login"
            src={signOut}
            text="Sign Out"
            onClick={logout}
          />
        </SidebarNav>
      </SidebarNavWrapper>
    </Sidebar>
  );
};
