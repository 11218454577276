import { Divider, Form } from "antd";
import { ResponsiveContainer } from "recharts";
import styled from "styled-components";

const DASHBOARD_COL_GAP = 20;
const FILTERS_COL_GAP = 10;

export const DashboardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${DASHBOARD_COL_GAP}px;
`;

export const DashboardCol = styled.div`
  flex: 0 0 calc(50% - ${DASHBOARD_COL_GAP / 2}px);
  max-width: calc(50% - ${DASHBOARD_COL_GAP / 2}px);
  min-width: 300px;
  border: 1px solid blue;
  padding: 20px 10px;
  @media (max-width: 1100px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const FiltersCol = styled.div`
  flex: 0 0 calc(50% - ${FILTERS_COL_GAP / 2}px);
  max-width: calc(50% - ${FILTERS_COL_GAP / 2}px);
`;

export const FiltersFormItem = styled(Form.Item)`
  .ant-row {
    display: flex;
    flex-direction: column;
  }
  .ant-col {
    width: 100%;
    text-align: left;
  }
`;

export const EfficiencyChartResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-surface {
    overflow: visible;
  }
`;

export const SelectedDateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 4px;
`;
