import { blue } from "@ant-design/colors";
import { Select, Modal, Button, Form, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Bar,
  YAxis,
  XAxis,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import {
  FiltersCol,
  FiltersContainer,
  FiltersFormItem,
  SelectedDateWrapper,
  StyledDivider,
} from "../../styled";
import { bayUtilizationChartMockData as data } from "./mock/chartsMock";
import dayjs from "dayjs";
import { FormFooter } from "../../../vehicles/styled";
import { useBayUtilization } from "../../../../hooks/bayUtilizationHook";
import { StyledSpin } from "../../../../styled";
import { CustomRangePicker } from "../CustomRangePicker";
import { StripedBar } from "../StripedBar";

const { Option } = Select;

interface OwnProps {
  chartHeight: number;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  selectedStartDate: number;
  setSelectedStartDate: Dispatch<SetStateAction<number>>;
  selectedEndDate: number;
  setSelectedEndDate: Dispatch<SetStateAction<number>>;
  filtersSumitted: boolean;
  setFiltersSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const BayUtilizationChart = ({
  chartHeight,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  filtersSumitted,
  setFiltersSubmitted,
}: OwnProps) => {
  const [bays, setBays] = useState(data);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const { mutate, data: bayUtilizationData, isLoading } = useBayUtilization();

  useEffect(() => {
    mutate({
      bays: bays.map((bay) => bay.bayValue),
      fromInUnixSeconds: selectedStartDate,
      toInUnixSeconds: selectedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string[]) => {
    if (value.includes("all")) {
      setBays(data);
      return;
    }
    setBays(data.filter((item) => value.includes(item.label)));
  };

  useEffect(() => {
    setBays(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filtersSumitted === true) {
      mutate({
        bays: bays.map((bay) => bay.bayValue),
        fromInUnixSeconds: selectedStartDate,
        toInUnixSeconds: selectedEndDate,
      });
      setFiltersSubmitted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSumitted])

  const YAxisFormatter = (value: string) => `${value}%`;

  const openFiltersModal = () => {
    setFiltersModalOpen(true);
  };

  const closeFiltersModal = () => {
    setFiltersModalOpen(false);
  };

  const onFiltersSubmit = () => {
    setFiltersSubmitted(true)
    closeFiltersModal();
  };

  return (
    <>
      <FiltersContainer>
        <Modal
          title="Filters"
          open={filtersModalOpen}
          onCancel={closeFiltersModal}
          footer={null}
          destroyOnClose
          // afterOpenChange={fillFormData}
          // afterClose={resetFormAndData}
        >
          <Form layout="vertical" onFinish={onFiltersSubmit}>
            <FiltersFormItem label="Select bays">
              <Select
                style={{ width: "100%" }}
                placeholder="Please select bays"
                onChange={handleChange}
                mode="multiple"
                allowClear
                onClear={() => setBays([])}
                value={bays.map((bay) => bay.value)}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {data.map((option: any) => (
                  <Option key={option.value} value={option.value}>
                    {option.value}
                  </Option>
                ))}
              </Select>
            </FiltersFormItem>
            <FiltersFormItem label="Select date range">
              <CustomRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
              />
            </FiltersFormItem>
            <FormFooter>
              <Button type="ghost" onClick={closeFiltersModal}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormFooter>
          </Form>
        </Modal>
        <FiltersCol>
          <Typography.Title style={{ margin: 0 }} level={3}>
            Bay Utilization
          </Typography.Title>
        </FiltersCol>
        <FiltersCol>
          <Button
            onClick={openFiltersModal}
            style={{ marginLeft: "auto", display: "block" }}
          >
            Filters
          </Button>
        </FiltersCol>
      </FiltersContainer>
      <SelectedDateWrapper>
        <span>
          {dayjs(startDate).format("MMM DD, YYYY")} -{" "}
          {dayjs(endDate).format("MMM DD, YYYY")}
        </span>
      </SelectedDateWrapper>
      <StyledDivider />
      {isLoading && <StyledSpin />}
      {!isLoading && (
        <ResponsiveContainer width={"100%"} height={chartHeight}>
          <ComposedChart data={bayUtilizationData}>
            <pattern
              id="pattern-stripe"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="4"
                height="8"
                transform="translate(0,0)"
                fill="white"
              ></rect>
            </pattern>
            <mask id="mask-stripe">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#pattern-stripe)"
              />
            </mask>
            <Bar
              dataKey="activeHours"
              stackId="a"
              name="Occupied"
              fill={blue.primary}
              barSize={170}
              label={({ x, y, width, value }) => {
                const dx = width / 2;
                return (
                  <text
                    x={x}
                    y={y}
                    dx={dx}
                    dy={20}
                    fill="#ffffff"
                    textAnchor="middle"
                    dominantBaseline="central"
                  >
                    {value}%
                  </text>
                );
              }}
            />
            <Bar
              dataKey="emptyHours"
              stackId="a"
              name="Unoccupied"
              fill={blue.primary}
              shape={<StripedBar />}
              stroke="#cccccc"
              legendType="none"
            />
            <XAxis dataKey="label" />
            <YAxis domain={[0, 100]} tickFormatter={YAxisFormatter} />
            {/* <Tooltip /> */}
            <Legend />
            {/*<Line*/}
            {/*  dataKey="activeHours"*/}
            {/*  name="Utilization"*/}
            {/*  stroke="#3d3d3d"*/}
            {/*  strokeWidth={2}*/}
            {/*/>*/}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
