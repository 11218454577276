import { DatePicker } from "antd";
import { rangePresets } from "./charts/mock/chartsMock";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

interface OwnProps {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  setSelectedStartDate: Dispatch<SetStateAction<number>>;
  setSelectedEndDate: Dispatch<SetStateAction<number>>;
}

const { RangePicker } = DatePicker;

export const CustomRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setSelectedStartDate,
  setSelectedEndDate,
}: OwnProps) => {
  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      setStartDate(dayjs(dates[0]).format());
      setEndDate(dayjs(dates[1]).endOf('d').format());
      setSelectedStartDate(dayjs(dates[0]).unix());
      setSelectedEndDate(dayjs(dates[1]).endOf('d').unix());
    } else {
      console.log("Clear");
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().add(-7, "hour").endOf("d");
  };

  return (
    <RangePicker
      presets={rangePresets}
      format="DD/MM/YYYY"
      onChange={onRangeChange}
      style={{ width: "100%" }}
      defaultValue={[dayjs(startDate), dayjs(endDate)]}
      disabledDate={disabledDate}
    />
  );
};
