import { useEffect } from "react";
import { MaintenanceBaysHeader } from "../styled";
import { Typography } from "antd";
import { MaintenanceBaysTable } from "../components/MaintenanceBaysTable";
import useMaintenaneBays from "../../../hooks/useMaintenanceBays";
import { convertMaintenanceBaysToTableData } from "../../vehicles/utils";
import { baysMock } from "../../../mocks/baysMock";

interface OwnProps {
  tableHeight: number;
}

export const MaintenanceBaysSettings = ({ tableHeight }: OwnProps) => {
  const { maintenanceBays, dispatch: dispatchMaintenanceBays } =
    useMaintenaneBays();

  useEffect(() => {
    dispatchMaintenanceBays?.({
      type: "SET_MAINTENANCE_BAYS",
      value:
        maintenanceBays.length > 0
          ? maintenanceBays
          : convertMaintenanceBaysToTableData(baysMock),
    });
  }, [maintenanceBays, dispatchMaintenanceBays]);

  return (
    <>
      <MaintenanceBaysHeader>
        <Typography.Title style={{ margin: 0, padding: "25px 0" }} level={3}>
          Maintenance Bays
        </Typography.Title>
      </MaintenanceBaysHeader>
      <MaintenanceBaysTable tableHeight={tableHeight} />
    </>
  );
};
