import { Tabs, TabsProps } from "antd";
import { VehiclesSettings } from "../vehicles/vehiclesSettings";
import { MaintenanceBaysSettings } from "../maintenanceBays/maintenanceBaysSettings";
import { PageContent } from "../styled";
import { useTrackOffset } from "../../hooks/trackOffsetHook";

export const SettingsPage = () => {
  const { containerRef, containerHeight } = useTrackOffset();
  const tableHeight = containerHeight - 240;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Vehicles",
      children: <VehiclesSettings tableHeight={tableHeight} />,
    },
    {
      key: "2",
      label: "Maintenance Bays",
      children: <MaintenanceBaysSettings tableHeight={tableHeight} />,
    },
  ];

  return (
    <PageContent ref={containerRef}>
      <Tabs defaultActiveKey="1" items={items} />
    </PageContent>
  );
};
