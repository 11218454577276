import { useQuery } from "react-query";
import { BASE_URL } from "../api";

export const fetchAlarmData = async (): Promise<number> => {
  const res = await fetch(`${BASE_URL}/MCS/Alarm`);
  return res.json();
};

export const useAlarm = () => {
  const { data, isLoading, error, isSuccess, refetch } = useQuery(
    "alarm",
    fetchAlarmData
  );

  return { data, isLoading, error, isSuccess, refetch };
};
