import {blue, gray, green, red} from "@ant-design/colors";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {Button, Input, Modal} from "antd";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

const {Search} = Input;

interface SearchableListItemProps {
  isActive: boolean;
  selected: boolean;
  isMobileView: boolean;
  isEmpty: boolean;
}

interface ActivityIconProps {
  active: boolean;
}

interface MobileViewProps {
  isMobileView?: boolean;
}

interface VehicleItemProps {
  isMobileView?: boolean;
  name?: string;
}

export const StyledLiveViewPageHeader = styled.div<MobileViewProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({isMobileView}) => (isMobileView ? "10px 20px" : "20px 0")};
`;

export const LiveViewPageHeaderButton = styled(Button)`
  border-radius: 6px;
`;

export const StyledLiveViewPageBody = styled.div<MobileViewProps>`
  display: flex;
  gap: 10px;
  height: ${({isMobileView}) =>
          isMobileView ? "calc(100% - 80px)" : "calc(100% - 93px)"};
`;

export const SearchableListContainer = styled.div`
  flex: 1;
`;

export const BayInfoContainer = styled.div<MobileViewProps>`
  flex: 0 0 300px;
  overflow-y: auto;
  ${({isMobileView}) =>
          isMobileView &&
          `
    position: fixed;
    inset: 0;
  `}
`;

export const BayInfoCard = styled.div<MobileViewProps>`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  ${({isMobileView}) =>
          isMobileView &&
          `
    height: 100%;
  `}
  border-radius: 4px;
`;

export const CardHeader = styled.div<MobileViewProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({isMobileView}) =>
          isMobileView ? "25px 10px 5px 10px" : "5px 10px"};
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 10px;

  .ant-btn {
    border-radius: 6px;
  }
`;

export const StyledVehicleInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 20px 10px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSearch = styled(Search)`
  .ant-input-affix-wrapper {
    border-bottom-left-radius: 0 !important;
  }

  .ant-input-search-button {
    border-bottom-right-radius: 0 !important;
  }
`;

export const StyledSearchableList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  border-radius: 4px;
`;

export const SearchableListItem = styled.div<SearchableListItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  cursor: pointer;
    /* &:first-child {
    border-top: ${({isMobileView}) =>
          isMobileView ? "1px solid #d9d9d9" : "none"};
  } */

  &:last-child {
    border-bottom: 1px solid #d9d9d9;
  }
  
  background: ${({selected, isActive, isEmpty}) =>
          selected ? "linear-gradient(to right, #C6EF93, #E4F0D4)" : isEmpty ? "transparent" : isActive ? "#E4F0D4" : "transparent"};
`;


export const ListItemInfo = styled.div<MobileViewProps>`
  display: flex;
  flex-direction: ${({isMobileView}) => (isMobileView ? "column" : "row")};
  gap: ${({isMobileView}) => isMobileView && "5px"};
  ${({isMobileView}) =>
          isMobileView &&
          `
    h5 {
      font-size: 18px;
    }
  `}
`;

export const StyledActivityIcon = styled.div<ActivityIconProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({active}) => (active ? green.primary : gray[0])};
  margin-right: 10px;
`;

export const VehiclesContainer = styled.div<MobileViewProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: ${({isMobileView}) => (isMobileView ? "0" : "15px")};
`;

export const StyledVehicleItem = styled.div<VehicleItemProps>`
  background-color: ${({name}) => {
    switch (name) {
      case "Semi-Truck":
        return "#497CFF";
      case "Blender":
        return "#23A437";
      case "Frac Pump":
        return "#D01616";
      case "Pickup Truck":
        return "#6816D0";
      case "Manifold":
        return "#FF9649";
      case "TCV":
        return "#C5C5C5";
      case "Hydration unit":
        return "#AAD016";
      default:
        return "#ffffff";
    }
  }};
  border: ${({name}) => (name === "Empty" ? "1px solid #999999" : "#ffffff")};
  color: ${({name}) => (name === "Empty" ? "#666666" : "#ffffff")};
  border-radius: ${({isMobileView}) => (isMobileView ? "0" : "2px")};
  padding: ${({isMobileView}) => (isMobileView ? "2px 5px" : "5px 15px")};
  font-size: 14px;
`;

export const StyledSmallText = styled.small<MobileViewProps>`
  border-radius: ${({isMobileView}) => (isMobileView ? "0" : "6px")};
  padding: ${({isMobileView}) =>
          isMobileView ? "2px 5px 2px 0" : "5px 15px"};
`;

export const StyledDuration = styled.div`
  font-size: 12px;
  color: #666666;
  padding-left: 10px;
`

export const StatusIconHolder = styled.div`
  margin-left: auto;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: 25px;

  path {
    fill: ${blue.primary};
  }
`;

export const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined
)`
  font-size: 25px;

  path {
    fill: ${red.primary};
  }
`;

export const LogoutButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${blue.primary};
  margin-left: 20px;
  margin-top: 10px;
  text-decoration: none;
`;

export const Styled3dViewModal = styled(Modal)`
  .ant-modal-body {
    min-height: 300px;
  }
`;
