import { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { VehiclesTable } from "../components/VehiclesTable";
import { VehiclesHeader } from "../styled";
import {
  CreateVehiclePayload,
  UpdateVehiclePayload,
  VehicleTableData,
} from "../../../api/types";
import { AddEditVehicleModal } from "../components/AddEditVehicleModal";
import {
  useAddVehicle,
  useDeleteVehicle,
  useUpdateVehicle,
  useVehicles,
} from "../../../hooks/vehicles-hook";

export enum ActionModes {
  CREATE = "create",
  EDIT = "edit",
}

interface OwnProps {
  tableHeight: number;
}

export const VehiclesSettings = ({ tableHeight }: OwnProps) => {
  const { refetch: refetchVehicles } = useVehicles();
  const { mutate: mutateAddVehicle } = useAddVehicle();
  const { mutate: mutateUpdateVehicle } = useUpdateVehicle();
  const { mutate: mutateDeleteVehicle } = useDeleteVehicle();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionMode, setActionMode] = useState<ActionModes>(ActionModes.CREATE);
  const [selectedVehicle, setSelectedVehicle] =
    useState<VehicleTableData | null>(null);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchVehicles();
    }, 30000);

    return () => {
      clearInterval(refetchInterval);
    };
  }, [refetchVehicles]);

  const addVehicle = (newVehicle: CreateVehiclePayload) => {
    mutateAddVehicle(newVehicle);
  };

  const updateVehicle = (vehicle: UpdateVehiclePayload, id: string) => {
    mutateUpdateVehicle({ vehicle, vehicleId: id });
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const enterMode = (mode: ActionModes, selectedVehicle?: VehicleTableData) => {
    setSelectedVehicle(selectedVehicle || null);
    setActionMode(mode);
    showModal();
  };

  const handleOk = (newVehicle: CreateVehiclePayload) => {
    addVehicle(newVehicle);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteVehicle = (id: string) => {
    mutateDeleteVehicle(id);
  };

  return (
    <>
      <VehiclesHeader>
        <Typography.Title style={{ margin: 0, padding: "25px 0" }} level={3}>
          Vehicles
        </Typography.Title>
        <Button onClick={() => enterMode(ActionModes.CREATE)} type="primary">
          Add vehicle
        </Button>
      </VehiclesHeader>
      <VehiclesTable
        tableHeight={tableHeight}
        enterMode={enterMode}
        deleteVehicle={deleteVehicle}
      />
      <AddEditVehicleModal
        open={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        actionMode={actionMode}
        selectedVehicle={selectedVehicle}
        setSelectedVehicle={setSelectedVehicle}
        updateVehicle={updateVehicle}
      />
    </>
  );
};
