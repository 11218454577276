import { useLocation } from "react-router-dom";
import { LinkAlert } from "./LinkAlert";
import { LinkImage } from "./LinkImage";
import { StyledSidebarLink } from "../styled";

interface OwnProps {
  to: string;
  text: string;
  alertValue?: number;
  src: string;
  onClick?: () => void;
}

export const SidebarLink = ({ to, text, alertValue, src, onClick }: OwnProps) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <StyledSidebarLink to={to} $active={pathname === to} onClick={onClick}>
      {<LinkImage src={src} />}{text} {!!alertValue && <LinkAlert value={alertValue as number} />}
    </StyledSidebarLink>
  );
};
