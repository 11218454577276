import { Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { MaintenanceBay, MaintenanceBayTableData } from "../../../api/types";
import useMaintenaneBays from "../../../hooks/useMaintenanceBays";

interface OwnProps {
  tableHeight: number;
}

export const MaintenanceBaysTable = ({ tableHeight }: OwnProps) => {
  const { maintenanceBays, dispatch } = useMaintenaneBays();
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (checked: boolean, record: MaintenanceBayTableData) => {
    setLoading(true);
    setTimeout(() => {
      const _maintenanceBays = [...maintenanceBays];
      const changedAlarm = _maintenanceBays.find(
        (alarm) => alarm.id === record.id
      );
      (changedAlarm as MaintenanceBayTableData).active = checked;
      dispatch?.({
        type: "SET_MAINTENANCE_BAYS",
        value: _maintenanceBays,
      });
      setLoading(false);
    }, 400);
  };

  const columns: ColumnsType<MaintenanceBay> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Camera ID",
      dataIndex: "cameraId",
    },
    {
      title: "Active",
      render: (record: MaintenanceBayTableData) => (
        <Switch
          defaultChecked={record.active}
          onChange={(e) => onChange(!record.active, record)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={maintenanceBays}
      pagination={false}
      loading={loading}
      scroll={{ y: tableHeight }}
      rowKey="id"
    />
  );
};
