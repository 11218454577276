import { StyledLinkImage } from '../styled'

interface OwnProps {
  src: string;
}

export const LinkImage = ({ src }: OwnProps ) => {
  return (
    <StyledLinkImage src={src} />
  )
}