import React, { createContext, useEffect, ReactNode, useState } from "react";

import { isMobile } from "react-device-detect";

interface OwnProps {
  children: ReactNode;
}

export type DeviceContextType = {
  isMobileView: boolean;
  windowHeight: number;
};

export const DeviceContext = createContext<DeviceContextType>({
  isMobileView: false,
  windowHeight: window.innerHeight,
});

export const DeviceProvider = ({ children }: OwnProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    setIsMobileView(isMobile && windowWidth < 768);
  }, [windowWidth]);

  const trackWidth = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", trackWidth);

    return () => {
      window.removeEventListener("resize", trackWidth);
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobileView, windowHeight }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceContext;
