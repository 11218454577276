export const useConvertTimeFormat = (timeString: string) => {
  const timePattern = /(\d+)d:(\d+)h:(\d+)m:(\d+)s/;
  const match = timeString.match(timePattern);

  if (!match) {
    return "0 sec";
  }

  const days = parseInt(match[1], 10);
  const hours = parseInt(match[2], 10);
  const minutes = parseInt(match[3], 10);
  const seconds = parseInt(match[4], 10);

  const formattedTime = [];

  if (days > 0) {
    formattedTime.push(`${days} day${days > 1 ? "s" : ""}`);
  }
  if (hours > 0) {
    formattedTime.push(`${hours} hr${hours > 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    formattedTime.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
  }
  if (seconds > 0) {
    formattedTime.push(`${seconds} sec`);
  }

  return formattedTime.join(", ");
};

export const useConvertTime = (vehicles: any[] | undefined) => {
  let maxDuration = "";

  vehicles?.forEach((vehicle) => {
    if (vehicle.duration > maxDuration) {
      maxDuration = vehicle.duration;
    }
  });

  return useConvertTimeFormat(maxDuration);
};
