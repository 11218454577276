import { Typography } from "antd";
import { OverviewVehicle, SearchableListItemType } from "../../../api/types";
import {
  ListItemInfo,
  SearchableListContainer,
  SearchableListItem,
  StatusIconHolder,
  StyledExclamationCircleOutlined,
  StyledInfoCircleOutlined,
  StyledSearch,
  StyledSearchableList,
  StyledSmallText,
} from "../styled";
import { ActivityIcon } from "./ActivityIcon";
import { Vehicles } from "./Vehicles";
import { StyledSpin } from "../../../styled";
import useDevice from "../../../hooks/useDevice";

interface OwnProps {
  items: SearchableListItemType[];
  selectedItem: number;
  handleItemSelection: (value: number) => void;
  onSearch: (value: string) => void;
  loading: boolean;
  searchPlaceholder: string;
  itemPrefix: string;
}

export const SearchableList = ({
  items,
  selectedItem,
  handleItemSelection,
  onSearch,
  loading,
  searchPlaceholder,
  itemPrefix,
}: OwnProps) => {
  const { isMobileView } = useDevice();

  return (
    <SearchableListContainer>
      {/* {!isMobileView && (
        <StyledSearch
          placeholder={searchPlaceholder}
          allowClear
          enterButton="Search"
          onSearch={onSearch}
        />
      )} */}
      {!loading && (
        <StyledSearchableList>
          { items?.map((item) => (
            <SearchableListItem
              key={item.key}
              onClick={() => handleItemSelection(item.key)}
              selected={selectedItem === item.key}
              isMobileView={isMobileView}
              isActive={item.isActive}
              isEmpty={item.isActive && item?.vehicles?.[0].type === 'Empty'}
            >
              <ActivityIcon active={item.isActive} />
              <ListItemInfo isMobileView={isMobileView}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {`${itemPrefix} ${item.key < 10 ? '0' + item.key : item.key}`}
                </Typography.Title>
                {!item.isActive ? (
                  <StyledSmallText isMobileView={isMobileView}>
                    Offline
                  </StyledSmallText>
                ) : (
                  item.vehicles && (
                    <Vehicles vehicles={item.vehicles as OverviewVehicle[]} />
                  )
                )}
                {item.location && <p>{item.location}</p>}
              </ListItemInfo>
              {isMobileView && (
                <StatusIconHolder>
                  {item.isActive ? (
                    <StyledInfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  ) : (
                    <StyledExclamationCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  )}
                </StatusIconHolder>
              )}
            </SearchableListItem>
          ))}
        </StyledSearchableList>
      )}
      {loading && <StyledSpin />}
    </SearchableListContainer>
  );
};
