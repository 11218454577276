import { ReactNode } from "react";
import { checkAuth } from "../utils/checkAuth";
import useAuth from "../../hooks/useAuth";
import { IUser } from "../../contexts/AuthContext";
import { Layout, LayoutContent, LayoutContentWrapper } from "./styled";
import { AppSidebar } from "../sidebar/AppSidebar";
import useDevice from "../../hooks/useDevice";

interface OwnProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: OwnProps) => {
  const { auth } = useAuth();
  const { isMobileView, windowHeight } = useDevice();

  return (
    <Layout windowHeight={windowHeight}>
      {checkAuth(auth as IUser) && <AppSidebar />}
      <LayoutContent
        checkAuth={checkAuth(auth as IUser)}
        isMobileView={isMobileView}
      >
        <LayoutContentWrapper isMobileView={isMobileView}>
          {children}
        </LayoutContentWrapper>
      </LayoutContent>
    </Layout>
  );
};
