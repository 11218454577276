import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../../../constants";
import { Typography } from "antd";
import { NavLink } from "react-router-dom";

interface SidebarLinkProps {
  $active?: boolean;
}

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${SIDEBAR_WIDTH}px;
  height: 100%;
  background-color: #1c1c1c;
  overflow-y: auto;
`;

export const SidebarTitle = styled(Typography.Title)`
  color: #ffffff !important;
  margin: 0 0 100px 0 !important;
  padding: 25px;
  text-align: center;
`;

export const SidebarNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 200px);
  padding-left: 16px;
  padding-right: 16px;
`;

export const SidebarNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledSidebarLink = styled(NavLink)<SidebarLinkProps>`
  padding: 10px 25px;
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  background-color: ${({ $active }) => ($active ? "#727272" : "transparent")};
  border-radius: 8px;
  margin-bottom: 4px;
  &:hover {
    background-color: #727272;
  }
`;

export const StyledLinkImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

export const StyledLinkAlert = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  color: #ffffff;
`;
