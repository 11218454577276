import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AlarmsProvider } from "./contexts/AlarmsContext";
import { DeviceProvider } from "./contexts/DeviceContext";
import { MaintenanceBaysProvider } from "./contexts/MaintenanceBaysContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AlarmsProvider>
          <DeviceProvider>
            <MaintenanceBaysProvider>
              <App />
            </MaintenanceBaysProvider>
          </DeviceProvider>
        </AlarmsProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
