import React from "react";
import { StyledActivityIcon } from "../styled";

interface OwnProps {
  active: boolean;
}

export const ActivityIcon = ({ active }: OwnProps) => {
  return <StyledActivityIcon active={active} />;
};
